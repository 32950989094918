import React from "react";
import "../../style/navbar_pages.css";
import img from "../../component/images/nozzle-filling-compound.jpg";
// import { companyDetails } from "../../data/complanyDetails";
import Sidebar from "./sidebar/Sidebar";

function NozzleFillingCompound() {
  return (
    <section className="container">
      <div className="row">
        <Sidebar />
        <div className="col-lg-8 col-md-12">
          <div className="div1">
            <h1>Nozzle Filling Compound</h1>
            <hr className="c-border-10 line1" />
            <p>
              Nozzle Filling Compound is a specialized refractory material
              designed for use in the steel industry, particularly in the lining
              and repair of nozzles in steel ladles and tundishes. Explore its
              chemical and physical properties, as well as its essential
              applications in the metallurgical processes.
            </p>
            {/* Include an image if available */}
            <img src={img} alt="" />
            <p style={{ textAlign: "center" }}>Nozzle Filling Compound</p>
          </div>

          <div className="div2">
            <h2 className="h3">Chemical Properties</h2>
            <hr className="c-border-10 line1" />
            <p>
              <b>Alumina-Silica Composition: </b>Nozzle Filling Compound is
              often composed of a mixture of high-quality alumina and silica
              refractory materials, providing excellent thermal stability and
              resistance to molten metal.
            </p>
            <p>
              <b>Binders: </b>Specialized binders may be included to enhance
              bonding and adhesion properties, ensuring effective application
              and durability.
            </p>
          </div>

          <div className="div3">
            <h2 className="h3">Physical Properties</h2>
            <hr className="c-border-10 line1" />
            <p>
              <b>Granular Form: </b>Nozzle Filling Compound is commonly
              available in granular form, facilitating easy installation and
              filling of nozzle areas.
            </p>
            <p>
              <b>High Temperature Resistance: </b>The compound exhibits high
              resistance to thermal shocks and extreme temperatures, making it
              suitable for applications in steelmaking processes.
            </p>
            <p>
              <b>Adhesion and Setting Time: </b>It has optimal adhesion
              properties and a controlled setting time, allowing for effective
              filling and repair of nozzles.
            </p>
          </div>

          <div className="div4">
            <h2 className="h3">Applications</h2>
            <hr className="c-border-10 line1" />
            <p>
              <b>Steel Ladles and Tundishes:</b> Nozzle Filling Compound is
              extensively used for lining and repairing nozzles in steel ladles
              and tundishes in the steelmaking industry.
            </p>
            <p>
              <b>Continuous Casting:</b> It plays a crucial role in continuous
              casting processes, ensuring the integrity of nozzles and
              facilitating smooth molten metal flow.
            </p>
            <p>
              <b>Foundry Applications:</b> In foundries, the compound is applied
              for repairing and maintaining nozzles in various metal casting
              processes.
            </p>
            {/* Include additional applications based on specific uses */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default NozzleFillingCompound;
