import React from "react";
import "../../style/navbar_pages.css";
import img from "../../component/images/induction-coil-coat.jpg";
// import { companyDetails } from "../../data/complanyDetails";
import Sidebar from "./sidebar/Sidebar";

function InductionFurnaceCoilCoat() {
  return (
    <section className="container">
      <div className="row">
        <Sidebar />
        <div className="col-lg-8 col-md-12">
          <div className="div1">
            <h1>Induction Furnace Coil Coat</h1>
            <hr className="c-border-10 line1" />
            <p>
              Induction Furnace Coil Coat is a specialized coating material
              designed for the protection and maintenance of induction furnace
              coils. It serves a crucial role in extending the lifespan of the
              coils and ensuring optimal performance in high-temperature
              applications. Explore its chemical and physical properties, as
              well as its essential applications in the metallurgical industry.
            </p>
            {/* Include an image if available */}
            <img src={img} alt="" />
            <p style={{ textAlign: "center" }}>Induction Furnace Coil Coat</p>
          </div>

          <div className="div2">
            <h2 className="h3">Chemical Properties</h2>
            <hr className="c-border-10 line1" />
            <p>
              <b>Heat-Resistant Components: </b>Induction Furnace Coil Coat
              typically contains heat-resistant components such as refractory
              materials, binders, and additives to withstand the extreme
              temperatures experienced in induction furnaces.
            </p>
            <p>
              <b>Adhesive Agents: </b>Specialized adhesive agents are often
              included to ensure the coat adheres well to the coil surface,
              providing effective protection against wear and corrosion.
            </p>
          </div>

          <div className="div3">
            <h2 className="h3">Physical Properties</h2>
            <hr className="c-border-10 line1" />
            <p>
              <b>High Temperature Resistance: </b>Induction Furnace Coil Coat
              exhibits high resistance to temperatures generated during metal
              melting processes, preventing damage to the induction furnace
              coils.
            </p>
            <p>
              <b>Thermal Insulation: </b>The coating material may have thermal
              insulation properties to minimize heat loss from the furnace and
              improve energy efficiency.
            </p>
            <p>
              <b>Corrosion Resistance: </b>It provides excellent corrosion
              resistance, protecting the coils from the corrosive effects of
              molten metal and slag.
            </p>
          </div>

          <div className="div4">
            <h2 className="h3">Applications</h2>
            <hr className="c-border-10 line1" />
            <p>
              <b>Induction Furnace Maintenance:</b> Induction Furnace Coil Coat
              is applied during routine maintenance to protect and extend the
              lifespan of induction furnace coils.
            </p>
            <p>
              <b>Metal Melting Processes:</b> It is essential for induction
              furnaces used in metal melting processes, ensuring the coils
              remain in optimal condition for efficient and reliable operation.
            </p>
            <p>
              <b>Foundry and Metallurgical Industry:</b> Coil coating is widely
              used in foundries and metallurgical plants to enhance the
              durability of induction furnace coils, reducing downtime and
              maintenance costs.
            </p>
            {/* Include additional applications based on specific uses */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default InductionFurnaceCoilCoat;
