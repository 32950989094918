// create a contact section like this:
// on the left side we have about the company || and on the right hand side we have the contact through whatsapp, call , email with an icon
// and all the styling should be in bootstrap

import AppWidget from "../Navbar_pages/appWidget/AppWidget";

const ContactFrame = () => {
  return (
    <section>
      <div className="container">
        <div className="row">
          {/* the about the section */}
          <div
            className="col-lg-6 col-sm-12"
            data-aos="fade-right"
            data-aos-delay="300"
          >
            <div className="main-title2">
              <h2 className="pb-3 title">About Us</h2>
              <p
                className="paragraph h5 text-gray"
                style={{ color: "#a5a3a3" }}
              >
                Welcome to Seema Industries, a trusted name in the world of
                refractory solutions for steel manufacturing. Established in
                2003, we take immense pride in our journey, and our commitment
                to excellence in manufacturing high-quality refractory materials
                has been unwavering.
              </p>
            </div>
          </div>
          {/* End main-title2 */}

          {/* the list and ways of contact */}
          <div
            className="col-lg-6 col-sm-12"
            data-aos="fade-left"
            data-aos-delay="300"
          >
            <div className="main-title2">
              <h2 className="pb-3 title">Contact Us</h2>
              <p className="paragraph fz15">You can contact us through,</p>

              <div>
                <AppWidget />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactFrame;
