import React from "react";
import "../../style/navbar_pages.css";
import img from "../../component/images/silica-ramming-mass.jpg";
import Sidebar from "./sidebar/Sidebar";

function SilicaRammingMass() {
  return (
    <section className="container">
      <div className="row">
        <Sidebar />
        <div className="col-lg-8 col-md-12">
          <div>
            <div className="div1">
              <h1>Silica Ramming Mass</h1>
              <hr className="c-border-10 line1" />
              <p>
                Silica ramming mass, also known as ramming material, is a
                refractory lining material used in induction furnaces to protect
                the inner walls of the furnace and maintain consistent heat
                levels. It plays a crucial role in various high-temperature
                applications, particularly in the steel and metallurgical
                industries. Here are detailed explanations of its chemical and
                physical properties.
              </p>
              <img src={img} alt="" />
              <p style={{ textAlign: "center" }}>Silica Ramming Mass</p>
            </div>

            <div className="div2">
              <h2 className="h3">Chemical Properties</h2>
              <hr className="c-border-10 line1" />
              <p>
                <b>Silica Content: </b>Silica ramming mass primarily consists of
                silica (SiO2), typically in the form of quartz. High silica
                content is essential for its resistance to thermal and chemical
                erosion.
              </p>
              <p>
                <b>Alumina (Al2O3): </b>It may contain a small amount of alumina
                to improve its stability at high temperatures and resist erosion
                from steel and slag.
              </p>
              <p>
                <b>Binder: </b>A binder, often a boric acid & Borone powder
                compound, is added to provide the necessary bonding and
                hardening properties when heated.
              </p>
            </div>

            <div className="div3">
              <h2 className="h3">Physical Properties</h2>
              <hr className="c-border-10 line1" />
              <p>
                <b>Granularity: </b>Silica ramming mass is granular and
                typically available in various grain sizes to suit specific
                furnace requirements.
              </p>
              <p>
                <b>Density: </b>The material has a high density, which allows it
                to create a protective, non-permeable lining in the furnace.
              </p>
              <p>
                <b>Thermal Conductivity: </b>Silica ramming mass exhibits low
                thermal conductivity, which helps to insulate the furnace lining
                and maintain a stable temperature inside.
              </p>
              <p>
                <b>Refractoriness: </b>It has a high refractoriness, with the
                ability to withstand extremely high temperatures encountered in
                steel melting applications.
              </p>
              <p>
                <b>Erosion Resistance: </b>The material is highly
                erosion-resistant, especially against the aggressive steel and
                slag components in the furnace.
              </p>
              <p>
                <b>Chemical Inertness: </b>Silica ramming mass is chemically
                inert, meaning it does not react with the molten metal or slag,
                preventing contamination.
              </p>
            </div>
            <div className="div4">
              <h2 className="h3">Applications</h2>
              <hr className="c-border-10 line1" />
              <p>
                <b>Induction Furnaces: </b>The primary application of silica
                ramming mass is as a refractory lining material in induction
                furnaces used in the steel industry. It protects the inner walls
                of the furnace from the extreme heat generated during the
                melting of steel.
              </p>
              <p>
                <b>Steel Production: </b>Silica ramming mass is used in both
                ferrous and non-ferrous metal production processes, such as
                making steel, iron, and non-ferrous alloys. It helps maintain
                the integrity of the furnace lining under intense temperature
                conditions.
              </p>
              <p>
                <b>Chemical Industry: </b>It is sometimes used in certain
                high-temperature chemical processes that require resistance to
                heat and chemical corrosion.
              </p>
              <p>
                <b>Foundries: </b> In foundries, it can be used as a refractory
                lining in furnaces for melting and alloying metals, including
                aluminum, copper, and zinc.
              </p>
              <p>
                <b>Glass Industry: </b>Silica ramming mass may be used in some
                specialized glass production processes where resistance to high
                temperatures is required.
              </p>
              <p>
                <b>Chemical Inertness: </b>In ceramic kilns and furnaces, it can
                be utilized to create stable and non-permeable linings that
                withstand high firing temperatures.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SilicaRammingMass;
