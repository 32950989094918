import "./App.css";
import "aos/dist/aos.css";
import Home from "./component/Home";
import Navbar from "./component/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Prod from "./component/Navbar_pages/Product";
import Contact from "./component/Navbar_pages/Contact";
import Header from "./component/Header";
import Footer from "./component/Footer1";
import SilicaRammingMass from "./component/Navbar_pages/SilicaRammingMass";
import AcidicRammingMass from "./component/Navbar_pages/AcidicRammingMass";
import NalitopRammingMass from "./component/Navbar_pages/NalitopRammingMass";
import QuartzSand from "./component/Navbar_pages/QuartzSand";
import SilicaSand from "./component/Navbar_pages/SilicaSand";
import BoricAcid from "./component/Navbar_pages/BoricAcid";
import Dolomite from "./component/Navbar_pages/Dolomite";
import NozzleFillingCompound from "./component/Navbar_pages/NozzleFillingCompound";
import InductionFurnaceCoilCoat from "./component/Navbar_pages/InductionFurnaceCoilCoat";
import { useEffect } from "react";
import Aos from "aos";

function App() {
  useEffect(() => {
    Aos.init({
      duration: 1200,
      once: true,
    });
  }, []);

  return (
    <div className="App">
      <Header />
      <Navbar />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<Prod />} />
          <Route path="/silica-ramming-mass" element={<SilicaRammingMass />} />
          <Route path="/acidic-ramming-mass" element={<AcidicRammingMass />} />
          <Route
            path="/nalitop-ramming-mass"
            element={<NalitopRammingMass />}
          />
          <Route path="/quartz-sand" element={<QuartzSand />} />
          <Route path="/silica-sand" element={<SilicaSand />} />
          <Route path="/boric-acid" element={<BoricAcid />} />
          <Route path="/dolomite" element={<Dolomite />} />
          <Route path="/nozzle-filling-compound" element={<NozzleFillingCompound />} />
          <Route path="/induction-furnace-coil-coat" element={<InductionFurnaceCoilCoat />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
