import React from "react";

import img1 from "./images/silica-ramming-mass.jpg";
import img2 from "./images/acidic-ramming-mass-manufacturer.jpg";
import img3 from "./images/Silica Sand.jpg";
import img4 from "./images/Quartz Sand.jpg";
import img5 from "./images/Nallitop-ramming-mass.jpg";
import img6 from "./images/nozzle-filling-compound.jpg";
import img7 from "./images/induction-coil-coat.jpg";
import img8 from "./images/boric-acid.jpg";
import img9 from "./images/dolomite.jpeg";

import { MdOutlineArrowOutward } from "react-icons/md";

import "../style/product.css";

const productData = [
  {
    id: 1,
    name: "Silica Ramming Mass",
    desc: "Silica ramming mass, also known as ramming material, is a refractory lining material used in induction...",
    link: "/silica-ramming-mass",
    img: img1,
  },
  {
    id: 2,
    name: "Acidic Ramming Mass",
    desc: "Acidic ramming mass, also known as acid ramming material, is a specialized refractory lining substance...",
    link: "/acidic-ramming-mass",
    img: img2,
  },
  {
    id: 3,
    name: "Silica Sand",
    desc: "Silica sand is a versatile and widely used industrial material known for its various applications ...",
    link: "/silica-sand",
    img: img3,
  },
  {
    id: 4,
    name: "Quartz Sand",
    desc: "Quartz sand, a versatile industrial material, finds widespread use in various applications...",
    link: "/quartz-sand",
    img: img4,
  },
  {
    id: 5,
    name: "Nalitop Ramming Mass",
    desc: "Nallitop Ramming Mass is a specialized refractory material designed for high-temperature applications...",
    link: "/nalitop-ramming-mass",
    img: img5,
  },
  {
    id: 6,
    name: "Boric Acid",
    desc: "Boric Acid is a versatile compound with numerous applications in various industries....",
    link: "/boric-acid",
    img: img6,
  },
  {
    id: 7,
    name: "Dolomite",
    desc: "Dolomite is a mineral composed of calcium magnesium carbonate widely recognized for its applications....",
    link: "/dolomite",
    img: img7,
  },
  {
    id: 8,
    name: "Nozzle Filling Compound ",
    desc: " Nozzle Filling Compound is a specialized refractory material designed for use in the steel industry,....",
    link: "/nozzle-filling-compound",
    img: img8,
  },
  {
    id: 9,
    name: "Induction Furnace Coil Coat",
    desc: "Induction Furnace Coil Coat is a specialized coating material designed for the protection and maintenance....",
    link: "/induction-furnace-coil-coat",
    img: img9,
  },
];

function Products() {
  return (
    <div className="container p-cont">
      <div className="row">
        {productData.map((item) => (
          <div className="col-lg-4 col-md-6 col-sx-12 c-card">
            <div
              data-aos="fade-up"
              data-aos-delay="100"
              key={item.id}
              className="border-0 card"
              // style={{ width: "20rem" }}
            >
              <img
                src={item.img}
                style={{ position: "relative", objectFit: "cover" }}
                className="card-img-top"
                alt={item.name}
                width="100%"
              />
              <div className="card-body">
                <h5 className="card-title c-title">{item.name}</h5>
                <p className="card-text text_p">
                  {item.desc.slice(0, 100)}...
                  <a href={item.link} rel="noopener noreferrer" target="_self">
                    read more <MdOutlineArrowOutward />
                  </a>
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Products;
