import "./AppWidget.css";
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";

const AppWidget = () => {
  const appList = [
    {
      icon: <FaWhatsapp className="text-white" size={40} />,
      text: "Message us on",
      title: "+91-9782500100",
      link: "https://wa.me/919782500100",
    },
    {
      icon: <MdOutlineMail className="text-white" size={40} />,
      text: "Email us at",
      title: "Info@seemaindustries.net",
      link: "mailto:Info@seemaindustries.net",
    },
    {
      icon: <FaPhoneAlt className="text-white" size={40} />,
      title: "+91-9782500100",
      text: "Call us at",
      link: "tel:+91-9782500100",
    },
  ];

  return (
    <div className="app-widget">
      <h5 className="text-white title mb10">Apps</h5>
      <div className="container">
        <div className="gap-3 justify-items-center mb-4 row mb-lg-5 align-items-center">
          {appList.map((app, index) => (
            <div className="col-auto col-sm-12" key={index}>
              <a
                href={app.link}
                target="_blank"
                rel="noopener noreferrer"
                className="w100"
              >
                <div className="app-info d-flex align-items-center mb10">
                  <div className="flex-shrink-0 app-icon">{app.icon}</div>
                  <div className="flex-grow-1 ml20">
                    <p className="text-center text-white app-text fz13 mb0">
                      {app.text}
                    </p>
                    <h6 className="text-white app-title fz14">{app.title}</h6>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AppWidget;
