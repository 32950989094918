import { useLocation } from "react-router-dom";
import { productNavigation } from "../../../data/navData";
import "./Sidebar.css";

const Sidebar = () => {
  const location = useLocation();
  const pathName = location.pathname;
  return (
    <div className="container mt-6 col-lg-4 hide-sm">
      <div className="row">
        <div className="gap-1 col-lg-12 col-md-12 d-flex flex-column">
          {productNavigation.map((item) => (
            <a
              key={item.id}
              href={item.link}
              className={`side-link ${item.link === pathName ? "current" : ""}`}
            >
              {item.name}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
