import React from "react";
import "../style/footer.css";
import logo from "./images/logo2.png";
import { productNavigation } from "../data/navData";
import { MdOutlineArrowOutward } from "react-icons/md";
import { FaEnvelope } from "react-icons/fa";
import { IoCall } from "react-icons/io5";

function Footer() {
  const homeClickHandler = () => {
    window.location.href = "/";
  };

  return (
    <>
      <div className="footer">
        <footer>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 item">
                <br></br>
                <div className="d-flex justify-content-center align-items-center" onClick={homeClickHandler}>
                  <div className="d-flex">
                    <img src={logo} alt="logo" className="logo" />
                  </div>
                  <div className="d-flex">
                    <h3 className="title">SEEMA INDUSTRIES</h3>
                  </div>
                </div>
                <li className="text-left lead">
                  Welcome to Seema Industries, a trusted name in the world of
                  refractory solutions for steel manufacturing. Established in
                  2003, we take immense pride in our journey, and our commitment
                  to excellence in manufacturing high-quality refractory
                  materials has been unwavering.
                </li>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-3 item pt-sm-30">
                <h5>PRODUCTS</h5>
                <ul className="d-flex flex-column center-sm">
                  {productNavigation.map((item) => (
                    <li key={item.id} className="text-left">
                      <a className="h6" href={item.link}>
                        {item.name}{" "}
                        <MdOutlineArrowOutward className="active-show" />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="d-flex flex-column justify-content-start align-items-center col-lg-3 col-md-6 col-sm-12 item pt-sm-30">
                <br></br>
                <h5>CONTACT US</h5>
                <ul className="d-flex flex-column center-sm">
                  <li>
                    <a className="h6" href="tel:+91-9782500100">
                      <IoCall />
                      +91-9782500100
                    </a>
                  </li>
                  <li>
                    <a className="h6" href="mailto:Info@seemaindustries.net">
                      <FaEnvelope />
                      Info@seemaindustries.net
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <p className="copyright">Seema Industries © 2003</p>
        </footer>
      </div>
    </>
  );
}

export default Footer;
