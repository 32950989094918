import React from "react";
import "../style/navbar.css";
import { IoCall } from "react-icons/io5";
import { FaEnvelope } from "react-icons/fa";

function Header() {
  return (
    <div className="header">
      <div className="header-container">
        <div className="header-left">
          <li>
            <a className="icon-text" href="tel:+91-9782500100">
              <IoCall /> +91-9782500100
            </a>
          </li>
        </div>
        <div className="header-right">
          <li>
            <a className="icon-text" href="mailto:Info@seemaindustries.net">
              <FaEnvelope /> Info@seemaindustries.net
            </a>
          </li>
        </div>
      </div>
    </div>
  );
}

export default Header;
