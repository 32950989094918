import React from "react";
import "../../style/contact.css";
import ContactFrame from "../Contact/ContactFrame";

function Contact() {
  // const [formData, setFormData] = useState({
  //   firstName: "",
  //   firstNameL:"",
  //   Phone: "",
  //     email: "",
  //     message: "",
  //   });

  //   const handleChange = (e) => {
  //     setFormData({ ...formData, [e.target.name]: e.target.value });
  //   };

  //   const handleSubmit = (e) => {
  //     e.preventDefault();
  //     console.log(formData);
  //   };

  return (
    <>
      <div>
        <h1 className="pl-5 h2">Our Office Address</h1>
        <div className="mapouter">
          <div className="gmap_canvas">
            <iframe
              title="gmap_iframe"
              className="gmap_iframe"
              width="100%"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=100&amp;height=100&amp;hl=en&amp;q=F73, Seema Industries: Quartz Grains and Silica/Acidic Ramming mass manufacturers near Jaipur
F-73, RIICO industrial area, Bapi, Dhai, Rajasthan 303303, India&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            ></iframe>
          </div>
        </div>
      </div>

      <ContactFrame />
    </>
  );
}

export default Contact;
