import React from "react";
import "../../style/navbar_pages.css";
import img from "../../component/images/acidic-ramming-mass-manufacturer.jpg";
// import { companyDetails } from "../../data/complanyDetails";
import Sidebar from "./sidebar/Sidebar";

function AcidicRammingMass() {
  return (
    <section className="container">
      <div className="row">
        <Sidebar />
        <div className="col-lg-8 col-md-12">
          <div>
            <div className="div1">
              <h1>Acidic Ramming Mass</h1>
              <hr className="c-border-10 line1" />
              <p>
                Acidic ramming mass, also known as acid ramming material, is a
                specialized refractory lining substance employed in
                high-temperature applications, notably in induction furnaces
                within industries such as steel and metallurgy. It serves the
                crucial function of safeguarding the furnace's inner walls,
                withstanding extreme temperatures, and resisting chemical
                erosion. Here's a comprehensive overview of its chemical and
                physical properties, as well as its applications.
              </p>
              <img src={img} alt="" />
              <p style={{ textAlign: "center" }}>Acidic Ramming Mass</p>
            </div>

            <div className="div2">
              <h2 className="h3">Chemical Properties</h2>
              <hr className="c-border-10 line1" />
              <p>
                <b>Silica Content: </b>Acidic ramming mass predominantly
                contains silica in the form of quartz. A high silica content is
                essential for its resilience against thermal and chemical wear
                and tear.
              </p>
              <p>
                <b>Alumina (Al2O3): </b> In some formulations, a small amount of
                alumina may be added to enhance stability at elevated
                temperatures and increase resistance to erosion from steel and
                slag.
              </p>
              <p>
                <b>Binder: </b>Acidic ramming mass incorporates a binder, often
                a combination of boric acid and boron powder. This binder
                provides the necessary bonding and hardening properties when
                subjected to heat.
              </p>
            </div>

            <div className="div3">
              <h2 className="h3">Physical Properties</h2>
              <hr className="c-border-10 line1" />
              <p>
                <b>Granularity: </b>Acidic ramming mass is granular and is
                available in various grain sizes to cater to specific furnace
                requirements.
              </p>
              <p>
                <b>Density: </b> It exhibits high density, facilitating the
                creation of a protective, impermeable lining within the furnace.
              </p>
              <p>
                <b>Thermal Conductivity: </b>With low thermal conductivity,
                acidic ramming mass effectively insulates the furnace lining,
                helping maintain a stable temperature within.
              </p>
              <p>
                <b>Refractoriness: </b>Possessing high refractoriness, it can
                endure extremely high temperatures encountered in processes such
                as steel melting.
              </p>
              <p>
                <b>Erosion Resistance: </b>Acidic ramming mass excels in erosion
                resistance, particularly against the corrosive steel and slag
                components present in the furnace.
              </p>
              <p>
                <b>Chemical Inertness: </b>Acidic ramming mass is chemically
                inert, preventing reactions with molten metal or slag and
                ensuring minimal contamination.
              </p>
            </div>
            <div className="div4">
              <h2 className="h3">Applications</h2>
              <hr className="c-border-10 line1" />
              <p>
                <b>Induction Furnaces: </b>Acidic ramming mass is primarily used
                as a refractory lining material in induction furnaces,
                protecting the inner walls from the intense heat generated
                during metal melting processes.
              </p>
              <p>
                <b>Steel and Metal Production: </b>Widely employed in the
                production of ferrous and non-ferrous metals, including steel,
                iron, and alloys, to maintain furnace lining integrity under
                extreme temperature conditions.
              </p>
              <p>
                <b>Chemical Industry: </b>Utilized in specific high-temperature
                chemical processes requiring resistance to both heat and
                chemical corrosion.
              </p>
              <p>
                <b>Foundries: </b> Applied as a refractory lining in furnaces
                for melting and alloying various metals, including aluminum,
                copper, and zinc.
              </p>
              <p>
                <b>Glass Industry: </b>In certain specialized glass production
                processes where resistance to high temperatures is crucial.
              </p>
              <p>
                <b>Chemical Inertness: </b>Used to create stable and impermeable
                linings in high-temperature environments, such as ceramic kilns
                and furnaces.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AcidicRammingMass;
