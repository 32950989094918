import React from "react";
import Front2 from "./Front2";
import Front1 from "./Front1";
import Products from "./Products";
import WhyChooseUs from "./WhyChooseUs";
const Home = () => {
  return (
    <div>
      <Front1 />
      <Front2 />
      <WhyChooseUs />
      <Products />
    </div>
  );
};

export default Home;
