export const productNavigation = [
  { id: 0, name: "Silica Ramming Mass", link: "/silica-ramming-mass" },
  { id: 1, name: "Acidic Ramming Mass", link: "/acidic-ramming-mass" },
  { id: 2, name: "Silica Sand", link: "/silica-sand" },
  { id: 3, name: "Quartz Sand", link: "/quartz-sand" },
  { id: 4, name: "Nalitop Ramming Mass", link: "/nalitop-ramming-mass" },
  { id: 5, name: "Boric Acid", link: "/boric-acid" },
  { id: 6, name: "Dolomite", link: "/dolomite" },
  { id: 7, name: "Nozzle Filling Compound", link: "/nozzle-filling-compound" },
  { id: 8, name: "Induction Furnace Coil Coat", link: "/induction-furnace-coil-coat" },

];
