import React from "react";
import Features from "./whychoose/Features";
import "./../style/whychooseus.css";
// import OIP from "./images/OIP.jpeg";
// import { FaIndustry } from "react-icons/fa6";

const WhyChooseUs = () => {
  return (
    <section>
      <div className="container">
        <div
          className="row align-items-md-center"
          data-aos="fade-left"
          data-aos-delay="100"
        >
          {/* <div className="col-md-6 col-lg-6">
            <div className="position-relative mb30-md"> */}
              {/* <img
                width={591}
                height={685}
                priority
                className="w-100 h-100 cover"
                src={OIP}
                alt="why chosse"
              /> */}
              {/* <a href="/single-v1/5">
                <div className="iconbox-style5 d-flex align-items-center"> */}
                  {/* <span className="flex-shrink-0 icon">
                    <FaIndustry size={40} color="white" />
                  </span> */}
                  {/* <div className="iconbox-content flex-shrink-1 ms-2">
                    <p className="mb-0 text">3000lts</p>
                    <h4 className="mb-0 title">Unit</h4>
                  </div> */}
                {/* </div>
              </a>
            </div>
          </div> */}
          {/* End .col-6 */}

          {/* <div
            className="col-md-6 col-lg-5 offset-lg-1 mt-md-30"
            data-aos="fade-right"
            data-aos-delay="300"
          > */}
            <div className="main-title2">
              <h2 className="title">Why Choose Us</h2>
              <p className="paragraph fz15">
                With a legacy dating back to our establishment in 2003, Seema
                Industries has been defined by a steadfast commitment to
                excellence.
              </p>
            </div>
            {/* End main-title2 */}

            <div className="why-chose-list">
              <Features />
            </div>
            {/* End .why-chose-list */}
          {/* </div> */}
          {/* End .col-6 */}
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
