import { GiChemicalTank, GiCheckMark } from "react-icons/gi";

const Features = () => {
  // Define an array of feature objects
  const features = [
    {
      icon: <GiChemicalTank size={50} color="white" />,
      title: "Commitment to High-Quality Manufacturing",
      description:
        "Our unwavering commitment to crafting high-quality refractory materials sets us apart.",
    },
    {
      icon: <GiCheckMark size={50} color="white" />,
      title: "Proven Track Record of Excellence",
      description:
        "Over the years, Seema Industries has established a proven track record of excellence.",
    },
    {
      icon: <GiChemicalTank size={50} color="white" />,
      title: "Customer-Centric Approach",
      description:
        "Customer satisfaction is at the core of our operations. We prioritize transparent communication, timely deliveries, and responsive customer support.",
    },
  ];

  return (
    <>
      {features.map((feature, index) => (
        <div
          className="gap-2 list-one d-flex align-items-start mb30"
          key={index}
        >
          <span
            className={`list-icon flex-shrink-0 d-flex justify-content-center align-items-center`}
          >
            {feature.icon}
          </span>
          <div className="pb-4 ml-2 list-content flex-grow-1 ml20">
            <h6 className="text-left h5 bold">{feature.title}</h6>
            <p className="mb-0 text h6">{feature.description}</p>
          </div>
        </div>
      ))}
    </>
  );
};

export default Features;
