export const homeData = [
  {
    id: 1,
    heading: "RAMMING MASS",
    desc: "Manufacturing and delivering top-quality Ramming Mass tailored to the stringent requirements of various industries. Our product boasts high purity and thermal stability, ensuring optimal performance in induction furnaces and steel melting applications.",
  },
  {
    id: 2,
    heading: "SILICA/QUARTZ SAND",
    desc: "Providing premium Silica/Quartz Sand with a SiO2 content ranging from 99.5% to 99.9%. Our sand, finely ground up to 500 mesh, is characterized by low iron content and resin test confirmation, making it an ideal choice for diverse applications, including glass manufacturing and foundries.",
  },
  {
    id: 3,
    heading: "Dolomite",
    desc: "Sourcing and delivering high-quality Dolomite for a range of applications. Our Dolomite, composed of calcium magnesium carbonate, finds utility in construction materials, agriculture, and as a vital component in refractory bricks for steel production.",
  },
  {
    id: 4,
    heading: "Boric Acid",
    desc: "Supplying premium-grade Boric Acid for diverse industrial applications. Our Boric Acid, with its unique chemical properties, serves as a flame retardant, pesticide, and preservative. It is a versatile solution with applications in industries ranging from electronics to agriculture.",
  },
  {
    id: 5,
    heading: "Nozzle Filling Compound",
    desc: "Specializing in Nozzle Filling Compounds designed to protect and maintain the integrity of induction furnace nozzles. Our compound, tailored for the steel industry, ensures smooth metal flow and extends the lifespan of nozzles, contributing to efficient steelmaking processes.",
  }
];
