import React from "react";
import "../../style/navbar_pages.css";
import img from "../../component/images/Nallitop-ramming-mass.jpg";
import Sidebar from "./sidebar/Sidebar";

function NalitopRammingMass() {
  return (
    <section className="container">
      <div className="row">
        <Sidebar />
        <div className="col-lg-8 col-md-12">
          <div className="div1">
            <h1>Nallitop Ramming Mass</h1>
            <hr className="c-border-10 line1" />
            <p>
              Nallitop Ramming Mass is a specialized refractory material
              designed for high-temperature applications, particularly in
              induction furnaces. Renowned for its exceptional properties, it
              plays a crucial role in protecting furnace walls and ensuring
              optimal performance in various industrial processes.
            </p>
            {/* Include an image if available */}
            <img src={img} alt="" />
            <p style={{ textAlign: "center" }}>Nallitop Ramming Mass</p>
          </div>

          <div className="div2">
            <h2 className="h3">Chemical Properties</h2>
            <hr className="c-border-10 line1" />
            <p>
              <b>Silica Content (SiO2): </b>Nallitop Ramming Mass primarily
              comprises silica (SiO2), often in the form of quartz. A high
              silica content is crucial for its resistance to thermal and
              chemical erosion, ensuring durability in high-temperature
              environments.
            </p>
            <p>
              <b>Alumina (Al2O3): </b>Depending on the formulation, a small
              amount of alumina may be added to enhance stability at high
              temperatures and increase resistance to erosion from steel and
              slag.
            </p>
            <p>
              <b>Binder: </b>Nallitop Ramming Mass incorporates a specialized
              binder to provide the necessary bonding and hardening properties
              when exposed to elevated temperatures.
            </p>
          </div>

          <div className="div3">
            <h2 className="h3">Physical Properties</h2>
            <hr className="c-border-10 line1" />
            <p>
              <b>Granularity: </b>Nallitop Ramming Mass is granular and
              available in various grain sizes to suit specific furnace
              requirements, ensuring optimal performance.
            </p>
            <p>
              <b>Density: </b>The material boasts high density, enabling the
              creation of a protective, non-permeable lining within the furnace
              for enhanced performance.
            </p>
            <p>
              <b>Thermal Conductivity: </b>Nallitop Ramming Mass exhibits low
              thermal conductivity, effectively insulating the furnace lining
              and maintaining stable temperatures.
            </p>
            {/* Include additional physical properties if available */}
          </div>

          <div className="div4">
            <h2 className="h3">Applications</h2>
            <hr className="c-border-10 line1" />
            <p>
              <b>Induction Furnaces:</b> Nallitop Ramming Mass is specifically
              designed for use as a refractory lining material in induction
              furnaces, offering superior protection against extreme
              temperatures during metal melting processes.
            </p>
            <p>
              <b>Steel Production: </b>Widely utilized in ferrous and
              non-ferrous metal production processes, Nallitop Ramming Mass
              ensures the integrity of furnace linings under intense temperature
              conditions.
            </p>
            <p>
              <b>Foundries: </b>Applied in foundries as a refractory lining for
              melting and alloying various metals, including aluminum, copper,
              and zinc, providing excellent refractory properties.
            </p>
            {/* Include additional applications based on specific uses */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default NalitopRammingMass;
