import React from "react";
import "../../style/navbar_pages.css";
import img from "../../component/images/boric-acid.jpg";
// import { companyDetails } from "../../data/complanyDetails";
import Sidebar from "./sidebar/Sidebar";

function BoricAcid() {
  return (
    <section className="container">
      <div className="row">
        <Sidebar />
        <div className="col-lg-8 col-md-12">
          <div className="div1">
            <h1>Boric Acid</h1>
            <hr className="c-border-10 line1" />
            <p>
              Boric Acid is a versatile compound with numerous applications in
              various industries. Known for its unique chemical properties, it
              serves important roles in manufacturing, medicine, and other
              sectors. Explore the detailed information on its chemical and
              physical properties below.
            </p>
            {/* Include an image if available */}
            <img src={img} alt="" />
            <p style={{ textAlign: "center" }}>Boric Acid</p>
          </div>

          <div className="div2">
            <h2 className="h3">Chemical Properties</h2>
            <hr className="c-border-10 line1" />
            <p>
              <b>Chemical Formula: </b>Boric Acid is represented by the chemical
              formula H3BO3. It consists of boron, oxygen, and hydrogen atoms in
              a crystalline structure.
            </p>
            <p>
              <b>Solubility: </b>Boric Acid is soluble in water, allowing for
              easy incorporation into various solutions and formulations.
            </p>
            <p>
              <b>Weak Acid: </b>Despite being classified as an acid, boric acid
              is a weak acid, meaning it does not fully dissociate in water and
              has a mild acidic nature.
            </p>
          </div>

          <div className="div3">
            <h2 className="h3">Physical Properties</h2>
            <hr className="c-border-10 line1" />
            <p>
              <b>Appearance: </b>Boric Acid typically presents as white,
              crystalline powder or colorless crystals, depending on the form
              and purity.
            </p>
            <p>
              <b>Melting Point: </b>Boric Acid has a relatively high melting
              point, contributing to its stability in various applications,
              including as a flame retardant.
            </p>
            <p>
              <b>Boiling Point: </b>At elevated temperatures, boric acid
              undergoes dehydration, losing water molecules and transitioning
              into metaboric acid.
            </p>
            {/* Include additional physical properties if available */}
          </div>

          <div className="div4">
            <h2 className="h3">Applications</h2>
            <hr className="c-border-10 line1" />
            <p>
              <b>Flame Retardant:</b> Boric Acid is widely used as a flame
              retardant in various materials, including textiles and wood, due
              to its ability to interfere with the combustion process.
            </p>
            <p>
              <b>Pesticides:</b> In agriculture, boric acid is utilized in the
              formulation of pesticides to control pests. It acts as an
              insecticide and fungicide.
            </p>
            <p>
              <b>Preservative:</b> Boric Acid serves as a preservative in
              certain products, including cosmetics and eye drops, due to its
              antimicrobial properties.
            </p>
            <p>
              <b>Medical Applications:</b> In medicine, boric acid is used in
              some topical antiseptics and eyewashes for its mild antibacterial
              properties.
            </p>
            <p>
              <b>Industrial Processes:</b> It finds application in the
              production of glass, ceramics, and enamel, where it acts as a
              flux.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BoricAcid;
