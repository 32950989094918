import React, { useState, useEffect, useRef } from "react";
import "../style/navbar.css";
import logo from "./images/logo2.png";
import { productNavigation } from "../data/navData";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdArrowDropdown } from "react-icons/io";

function Navbar() {
  const [isToggle, setToggle] = useState(false);
  const [isDropdown, setDropdown] = useState(false);
  const [dropdownStyle, setDropdownStyle] = useState({});
  const navbarRef = useRef(null);
  const dropdownRef = useRef(null);

  const toggleDropdown = (event) => {
    setDropdown(!isDropdown);

    // Wait for the next render cycle when the dropdown is visible
    setTimeout(() => {
      if (dropdownRef.current) {
        // const rect = dropdownRef.current.getBoundingClientRect();
        const defineLeft = window.innerWidth > 1280 ? event.clientX - 150 : (window.innerWidth - 400 - 10) > 0 ? (window.innerWidth - 400 - 10) : 0;
        const defineTop = event.clientY < 50 ? event.clientY + 30 : 70;
        const style = {
          left: `${defineLeft}px`,
          top: `${defineTop}px`,
        };

        setDropdownStyle(style);
      }
    }, 0);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className="sticky-header" ref={navbarRef}>
      <nav className="nav">
        <div className="icon-section">
          {/* <li className="logo"> */}
          <img className="logo d-inline" src={logo} alt="Logo" />
          {/* </li> */}
          <div className="d-inline h3 title">Seema Industries</div>
          <GiHamburgerMenu
            onClick={() => setToggle((prev) => !prev)}
            className="ham"
          />
        </div>
        <ul className={`navigation ${isToggle && "active"}`}>
          <li className="nav-line">
            <a href="/">Home</a>
          </li>

          <li className="dropdown nav-line">
            <button
              className="dropbtn"
                onClick={toggleDropdown}
              >
              Products
              <IoMdArrowDropdown />
            </button>
          </li>

          <div className={`dropdown-content ${isDropdown && "active"}`} style={dropdownStyle} ref={dropdownRef}>
              {productNavigation.map((item) => (
                <li key={item.id}>
                  <a href={item.link}>{item.name}</a>
                </li>
              ))}
          </div>

          <ul className={`mobile-dropdown ${isDropdown && "active"}`}>
            {productNavigation.map((item) => (
              <li key={item.id}>
                <a href={item.link}>{item.name}</a>
              </li>
            ))}
          </ul>
          <li className="nav-line">
            <a href="/contact">Contact Us</a>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Navbar;
