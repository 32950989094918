import React from "react";
import "../../style/navbar_pages.css";
import img from "../../component/images/Silica Sand.jpg";
import Sidebar from "./sidebar/Sidebar";

function SilicaSand() {
  return (
    <section className="container">
      <div className="row">
        <Sidebar />
        <div className="col-lg-8 col-md-12">
          <div className="div1">
            <h1>Silica Sand</h1>
            <hr className="c-border-10 line1" />
            <p>
              Silica sand is a versatile and widely used industrial material
              known for its various applications in construction, glass
              manufacturing, foundry work, and many other fields. Here are
              detailed explanations of its chemical and physical properties{" "}
            </p>
            <img src={img} alt="" />
            <p style={{ textAlign: "center" }}>Silica Sand</p>
          </div>

          <div className="div2">
            <h2 className="h3">Chemical Properties</h2>
            <hr className="c-border-10 line1" />
            <p>
              <b>Silica Content (SiO2): </b>Silica sand is primarily composed of
              silicon dioxide (SiO2), typically in the form of quartz. The
              purity of the silica content can vary, but high-purity silica
              sands are commonly used in many applications.
            </p>
            <p>
              <b>Impurities: </b>Silica sand may contain various impurities,
              such as iron oxides (Fe2O3 and Fe3O4), aluminum oxide (Al2O3),
              calcium oxide (CaO), magnesium oxide (MgO), and other minerals.
              The presence of impurities can affect the color and quality of the
              sand.
            </p>
          </div>

          <div className="div3">
            <h2 className="h3">Physical Properties</h2>
            <hr className="c-border-10 line1" />
            <p>
              <b>Particle Size: </b>Silica sand comes in a range of particle
              sizes, from very fine to coarse. The size of the particles can
              impact its suitability for specific applications.
            </p>
            <p>
              <b>Density: </b>Silica sand has a relatively high density, which
              is advantageous in applications where it's used as a filler or in
              mixtures to increase the density of a material.
            </p>
            <p>
              <b>Hardness: </b>Quartz, the main component of silica sand, is one
              of the hardest minerals, making silica sand highly resistant to
              abrasion and wear.
            </p>
            <p>
              <b>Melting Point: </b>Silica sand has a high melting point, which
              makes it suitable for use in the manufacturing of glass and
              ceramics, as it can withstand the high temperatures required for
              these processes.
            </p>
            <p>
              <b>Chemical Inertness: </b> Silica sand is chemically inert,
              meaning it doesn't react with most substances, which makes it an
              ideal material for use in various applications.
            </p>
          </div>
          <div className="div4">
            <h2 className="h3">Applications</h2>
            <hr className="c-border-10 line1" />
            <p>
              <b>Glass Manufacturing:</b>TSilica sand is a primary raw material
              in glass production. Its high silica content and purity are
              crucial for producing clear, high-quality glass products, such as
              bottles, windows, and glassware.{" "}
            </p>
            <p>
              <b>Ceramics: </b>Silica sand is used in the production of
              ceramics, including pottery, porcelain, and sanitary ware. It's
              often mixed with clay and other materials to form ceramic bodies
              due to its refractory properties and fine particle size.
            </p>
            <p>
              <b>Foundry Work: </b>Silica sand is used in foundries to make
              molds and cores for metal casting. It provides the necessary
              refractory properties, as well as good permeability and bonding
              characteristics.
            </p>
            <p>
              <b>Construction: </b> Silica sand is a key ingredient in the
              production of concrete and mortar. It enhances the durability and
              workability of these materials.
            </p>
            <p>
              <b>Sports and Recreation: </b>Fine silica sand is used in sports
              fields and golf course bunkers for its ability to drain water and
              maintain stability.
            </p>
            <p>
              <b>Oil and Gas Industry: </b>Silica sand is used in hydraulic
              fracturing (fracking) operations as a proppant to keep fractures
              open, allowing the extraction of oil and natural gas.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SilicaSand;
