// Front2.js

import React from "react";
import "../style/front2.css";
import { homeData } from "../data/homeData";

// list of random color which look great in grid
const colorList = {
  0: "#f94144",
  1: "#f3722c",
  2: "#f8961e",
  3: "#f9844a",
  4: "#f9c74f",
  5: "#90be6d",
  6: "#43aa8b",
};

function Front2() {
  return (
    <section style={{ background: "#f5f5f5" }}>
      <div className="container1">
        {homeData.map((item) => (
          <div
            id={item.id}
            className="border-0 card"
            style={{ backgroundColor: colorList[item.id % 7] }}
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="card-body">
              <h5 className="card-title">{item.heading}</h5>
              <p className="card-text">{item.desc}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Front2;
