import React from "react";
import "../../style/navbar_pages.css";
import img from "../../component/images/Quartz Sand.jpg";
import Sidebar from "./sidebar/Sidebar";

function QuartzSand() {
  return (
    <section className="container">
      <div className="row">
        <Sidebar />
        <div className="col-lg-8 col-md-12">
          <div className="div1">
            <h1>Quartz Sand</h1>
            <hr className="c-border-10 line1" />
            <p>
              Quartz sand, a versatile industrial material, finds widespread use
              in various applications, including construction, glass
              manufacturing, electronics, and more. Explore the detailed
              chemical and physical properties below.
            </p>
            <img src={img} alt="" />
            <p style={{ textAlign: "center" }}>Quartz Sand</p>
          </div>

          <div className="div2">
            <h2 className="h3">Chemical Properties</h2>
            <hr className="c-border-10 line1" />
            <p>
              <b>Silica Content (SiO2): </b>Quartz sand is predominantly
              composed of silicon dioxide (SiO2) in the form of crystalline
              quartz. High purity is often sought for applications demanding
              clarity and precision.
            </p>
            <p>
              <b>Impurities: </b>Similar to silica sand, quartz sand may contain
              trace impurities like iron oxides, aluminum oxide, and other
              minerals, influencing its color and quality.
            </p>
          </div>

          <div className="div3">
            <h2 className="h3">Physical Properties</h2>
            <hr className="c-border-10 line1" />
            <p>
              <b>Particle Size: </b>Quartz sand exhibits a range of particle
              sizes, suitable for various applications. The particle size
              influences its performance in specific uses.
            </p>
            <p>
              <b>Density: </b>With a relatively high density, quartz sand is
              advantageous in applications where increased material density is
              desirable, such as in the production of high-density concrete.
            </p>
            <p>
              <b>Hardness: </b>Quartz, being one of the hardest minerals,
              contributes to the high abrasion resistance and durability of
              quartz sand, making it suitable for demanding applications.
            </p>
            <p>
              <b>Melting Point: </b>Quartz sand boasts a high melting point,
              rendering it suitable for applications in glass and ceramic
              manufacturing where exposure to high temperatures is inevitable.
            </p>
            <p>
              <b>Chemical Inertness: </b>Similar to silica sand, quartz sand is
              chemically inert, making it resistant to reactions with most
              substances and ideal for diverse applications.
            </p>
          </div>

          <div className="div4">
            <h2 className="h3">Applications</h2>
            <hr className="c-border-10 line1" />
            <p>
              <b>Glass Manufacturing:</b> Quartz sand is a crucial raw material
              in glass production, ensuring clarity and strength in glass
              products, including optical lenses, windows, and high-performance
              glass.
            </p>
            <p>
              <b>Electronics: </b>Used in the production of electronic
              components, quartz sand is integral in manufacturing
              semiconductors and other electronic devices due to its high purity
              and thermal stability.
            </p>
            <p>
              <b>Ceramics: </b>Quartz sand is a key ingredient in ceramic
              production, contributing to the refractory properties and fine
              particle size necessary for forming ceramic bodies.
            </p>
            <p>
              <b>Construction: </b>Widely used in construction materials, quartz
              sand enhances the strength and durability of concrete and mortar.
            </p>
            <p>
              <b>Water Filtration: </b>Due to its uniform particle size and
              chemical inertness, quartz sand is employed in water filtration
              systems for efficient particle removal.
            </p>
            <p>
              <b>Oil and Gas Industry: </b>Quartz sand is used as a proppant in
              hydraulic fracturing to maintain fractures open and facilitate the
              extraction of oil and natural gas.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default QuartzSand;
