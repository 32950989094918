import React from "react";
// import coverPhoto from './images/interior-view-steel-factory.jpg'
import "../style/front1.css";

function Front1() {
  return (
    <>
      <div className="hero_content">
        <div className="container center">
          <div className="row">
            <div className="col-sm-12">
              <h1 data-aos="fade-up" data-aos-delay="100" className="style1">
                Seema Industries
              </h1>
              <h2 data-aos-delay="400" data-aos="fade-up" className="style2">
                Manufacturer & Exporters of finest Quality
              </h2>
              <h2 data-aos-delay="600" data-aos="fade-up" className="style2">
                Quartz granules & powder
              </h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Front1;
