import React from "react";
import "../../style/navbar_pages.css";
import img from "../../component/images/dolomite.jpeg";
// import { companyDetails } from "../../data/complanyDetails";
import Sidebar from "./sidebar/Sidebar";

function Dolomite() {
  return (
    <section className="container">
      <div className="row">
        <Sidebar />
        <div className="col-lg-8 col-md-12">
          <div className="div1">
            <h1>Dolomite</h1>
            <hr className="c-border-10 line1" />
            <p>
              Dolomite is a mineral composed of calcium magnesium carbonate,
              widely recognized for its applications in various industries.
              Explore the chemical and physical properties, as well as its
              diverse range of applications.
            </p>
            {/* Include an image if available */}
            <img src={img} alt="" />
            <p style={{ textAlign: "center" }}>Dolomite</p>
          </div>

          <div className="div2">
            <h2 className="h3">Chemical Properties</h2>
            <hr className="c-border-10 line1" />
            <p>
              <b>Composition: </b>Dolomite is primarily composed of calcium
              carbonate (CaCO3) and magnesium carbonate (MgCO3) in varying
              proportions.
            </p>
            <p>
              <b>Crystalline Structure: </b>It often forms rhombohedral crystals
              and can exhibit a range of colors depending on impurities.
            </p>
          </div>

          <div className="div3">
            <h2 className="h3">Physical Properties</h2>
            <hr className="c-border-10 line1" />
            <p>
              <b>Hardness: </b>Dolomite is relatively hard, making it suitable
              for various industrial applications, including as a source of
              magnesium in the production of refractory bricks.
            </p>
            <p>
              <b>Density: </b>The density of dolomite contributes to its use in
              construction materials and as an aggregate in concrete.
            </p>
            {/* Include additional physical properties if available */}
          </div>

          <div className="div4">
            <h2 className="h3">Applications</h2>
            <hr className="c-border-10 line1" />
            <p>
              <b>Construction Industry:</b> Dolomite is used as a construction
              material, particularly in the production of concrete and asphalt.
            </p>
            <p>
              <b>Agriculture:</b> It is employed in agriculture to neutralize
              soil acidity and provide essential nutrients to plants.
            </p>
            <p>
              <b>Refractory Industry:</b> Dolomite is utilized in the production
              of refractory bricks, where it serves as a source of magnesium
              oxide.
            </p>
            {/* Include additional applications based on specific uses */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Dolomite;
